const popup = document.getElementById('popup');
const popupForm = document.getElementById('popup-form');

const popupOpenButton = document.querySelector('.header__button');
const popupCloseButton = document.querySelector('.popup__button-close');

const popupOpenButtonInHeader = document.getElementById('header__popup-button');

let escKeyListener = null;

function openPopup() {
  popup.classList.add('active');
  document.body.classList.add('no-scroll');

  escKeyListener = (event) => {
    if (event.key === 'Escape' && popup.classList.contains('active')) {
      closePopup();
      document.removeEventListener('keydown', escKeyListener);
    }
  };

  document.addEventListener('keydown', escKeyListener);
}

function closePopup() {
  popup.classList.remove('active');
  popupForm.classList.remove('hidden');
  document.body.classList.remove('no-scroll');

  document.removeEventListener('keydown', escKeyListener);
}

popupOpenButton.addEventListener('click', openPopup);
popupOpenButtonInHeader.addEventListener('click', openPopup);
popupCloseButton.addEventListener('click', closePopup);
